import { render, staticRenderFns } from "./DepartmentsList.vue?vue&type=template&id=50f72aca&scoped=true&"
import script from "./DepartmentsList.vue?vue&type=script&lang=js&"
export * from "./DepartmentsList.vue?vue&type=script&lang=js&"
import style0 from "./DepartmentsList.vue?vue&type=style&index=0&id=50f72aca&prod&lang=scss&"
import style1 from "./DepartmentsList.vue?vue&type=style&index=1&id=50f72aca&prod&lang=sass&scoped=true&"
import style2 from "./DepartmentsList.vue?vue&type=style&index=2&id=50f72aca&prod&lang=scss&"
import style3 from "@riophae/vue-treeselect/dist/vue-treeselect.min.css?vue&type=style&index=3&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "50f72aca",
  null
  
)

export default component.exports