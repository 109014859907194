<template>
  <section>
    <b-card
      no-body
      class="coupon-card"
    >
      <b-card-header>
        <div
          class="d-flex flex-wrap justify-content-between align-items-center mb-2 w-100"
          style="gap: 8px;"
        >
          <div>
            <h4>{{ $t('List of departments') }}</h4>
            <!-- <div>{{ $t('Create combos with attractive offers that will attract more buyers!') }}</div> -->
          </div>
          <b-button
            variant="primary"
            type="button"
            class="ml-2"
            to="add"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-50"
            />
            {{ $t('Create new') }}
          </b-button>
        </div>

        <!-- <div class="filter-tab w-100">
          <div
            v-for="item in filterTabs"
            :key="item.id"
            :class="['filter-tab__item', {'active': filterSelected == item.id}]"
            @click="filterSelected = item.id"
          >
            {{ $t(item.label) }}
          </div>
        </div> -->
      </b-card-header>
      <b-card-body>
        <!-- table -->
        <vue-good-table
          ref="inventory-receives"
          style-class="vgt-table"
          class="custom-good-table"
          :columns="fields"
          :rows="filterItems"
          :search-options="{
            enabled: true,
            externalQuery: searchTerm }"
          :pagination-options="{
            enabled: true,
            perPage:pageLength
          }"
        >
          <template
            slot="table-row"
            slot-scope="props"
          >
            <span
              v-if="props.column.field === 'status'"
            >
              <b-form-checkbox
                checked="true"
                name="check-button"
                switch
                inline
                class="mx-auto custom-control-success"
              />
            </span>
            <span
              v-if="props.column.field === 'name'"
            >
              {{ props.row.name }}
            </span>
            <span
              v-if="props.column.field === 'count'"
            >
              {{ props.row.count }}
            </span>
            <span
              v-if="props.column.field === 'created_at'"
            >
              {{ props.row.created_at }}
            </span>
            <span
              v-if="props.column.field === 'tool'"
            >
              <template>
                <b-link
                  to="/"
                  class="text-secondary d-block mt-1"
                >
                  {{ $t('Edit') }}
                </b-link>
                <b-link
                  to="/"
                  class="text-secondary d-block mt-1"
                >
                  {{ $t('Close') }}
                </b-link>
              </template>
            </span>
          </template>

          <!-- pagination -->
          <template
            slot="pagination-bottom"
            slot-scope="props"
          >
            <div class="d-flex justify-content-between flex-wrap">
              <div class="d-flex align-items-center mb-0 mt-1">
                <span class="text-nowrap ">
                  Showing 1 to
                </span>
                <b-form-select
                  v-model="pageLength"
                  :options="pageOptions"
                  class="mx-1"
                  @input="(value)=>props.perPageChanged({currentPerPage:value})"
                />
                <span class="text-nowrap"> of {{ props.total }} entries </span>
              </div>
              <div>
                <b-pagination
                  :value="1"
                  :total-rows="props.total"
                  :per-page="pageLength"
                  first-number
                  last-number
                  align="right"
                  prev-class="prev-item"
                  next-class="next-item"
                  class="mt-1 mb-0"
                  @input="(value)=>props.pageChanged({currentPage:value})"
                >
                  <template #prev-text>
                    <feather-icon
                      icon="ChevronLeftIcon"
                      size="18"
                    />
                  </template>
                  <template #next-text>
                    <feather-icon
                      icon="ChevronRightIcon"
                      size="18"
                    />
                  </template>
                </b-pagination>
              </div>
            </div>
          </template>
        </vue-good-table>
      </b-card-body>
    </b-card>
  </section>
</template>

<script>
/* eslint-disable array-callback-return */
import {
  // BTabs,
  // BTab
  BCard,
  BCardHeader,
  BCardBody,
  BButton,
  BFormSelect,
  BPagination,
  BFormCheckbox,
  BLink,
} from 'bootstrap-vue'
import { VueGoodTable } from 'vue-good-table'
// import Request from '@/plugins/service/index'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import general from '@/mixins/index'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardBody,
    BButton,
    BFormSelect,
    BPagination,
    VueGoodTable,
    BFormCheckbox,
    BLink,
  },
  mixins: [general],
  data() {
    return {
      statisticsItems: [
        {
          icon: 'BoxIcon',
          color: 'light-primary',
          title: '2300',
          subtitle: 'Số lượng tồn kho',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ShoppingCartIcon',
          color: 'light-info',
          title: '8.549M',
          subtitle: 'Tổng giá bán',
          customClass: 'mb-2 mb-xl-0',
        },
        {
          icon: 'ThumbsUpIcon',
          color: 'light-danger',
          title: '1.423M',
          subtitle: 'Tổng giá trị',
          customClass: 'mb-2 mb-sm-0',
        },
        {
          icon: 'DollarSignIcon',
          color: 'light-success',
          title: '7.126M',
          subtitle: 'Lợi nhuận',
          customClass: '',
        },
      ],
      sortBy: '',
      sortDesc: false,
      sortDirection: 'asc',
      filter: null,
      filterOn: [],
      pageOptions: [5, 10, 20, 50, 100],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageLength: 10,
      searchTerm: '',
      filterSelected: 0,
      dateRangeConfig: {
        mode: 'range',
        dateFormat: 'd-m-Y',
        maxDate: 'today',
      },
      model: {
        time: null,
        status: null,
        category_id: null,
      },
      supplierOptions: [],
      fields: [
        {
          field: 'name',
          label: 'Tên phòng',
          type: 'number',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'count',
          label: 'Thành Viên',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'created_at',
          label: 'Ngày tạo',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'status',
          label: 'Trạng thái',
          tdClass: 'text-center',
          sortable: false,
        },
        {
          field: 'tool',
          label: 'Công cụ',
          tdClass: 'text-center',
          sortable: false,
        },
      ],
      items: [
        {
          name: 'Phòng kỹ thuật',
          created_at: '07/07/2022 15:30',
          status: 1,
          count: 20,
        },
        {
          name: 'Phòng kế toán',
          created_at: '07/07/2022 15:30',
          status: 0,
          count: 22,
        },
      ],
    }
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        0 : 'light-dark',
        1     : 'light-success',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    filterItems() {
      if (this.filterSelected === 0) return this.items
      return this.items.filter(item => item.status === this.filterSelected)
    },
  },
  created() {
  },
  methods: {
    onDatePicker(selectedDates, dateStr) {
      if (dateStr.split(' ').length !== 1) {
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.from = dateStr.split(' ')[0]
        // eslint-disable-next-line prefer-destructuring
        this.rangeDate.to = dateStr.split(' ')[2]
      }
    },
    // onDateChange(dateFrom, dateTo) {
    // },
    doCopy(text, position = 'top-center') {
      this.$copyText(text).then(() => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thành công',
            icon: 'BellIcon',
          },
        },
        {
          position,
        })
      }, () => {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: 'Copy thất bại',
            icon: 'BellIcon',
          },
        })
      })
    },
    handlerChangeCategory() {
      // eslint-disable-next-line array-callback-return
      this.items.filter(x => {
        if (x.slug === this.model.cat_slug) {
          this.model.parent_id = x.idx
          this.model.parent_slug = x.slug
        } else {
          // eslint-disable-next-line array-callback-return
          x.children.filter(y => {
            if (y.slug === this.model.cat_slug) {
              this.model.parent_id = y.idx
              this.model.parent_slug = y.slug
            }
          })
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>

<style lang="sass" scoped>
.promotion-head
  .sub-title
    font-size: 12px
    color: #a6a4b0
  .item
    border-right: 1px solid #e5e5e5
    padding-top: 5px
    padding-bottom: 5px
    margin-right: 30px

    &:last-child
      border: none

    .price
      margin-top: 5px
      margin-bottom: 5px
      font-weight: bold
      font-size: 20px

  .d-grid
    display: grid
    grid-template-columns: auto auto auto auto

  .flatpickr-input
    visibility: hidden

  .sub-title
    cursor: pointer

.filter
  &-tab
    display: flex
    overflow: hidden
    flex: none
    border-bottom: 1px solid #e5e5e5

    &__item
      min-width: 64px
      padding: 0 16px
      height: 56px
      display: flex
      align-items: center
      font-weight: 500
      font-size: 14px
      border-bottom: 3px solid transparent
      cursor: pointer
      transition: all .2s

      &.active,
      &:hover
        color: var(--primary)

      &.active
        border-bottom-color: var(--primary)
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';

.custom-good-table .vgt-table {
  font-size: 14px;

  thead th {
    vertical-align: middle;
    text-align: center;
    padding-right: 0.75em;
    white-space: nowrap;
  }
  td {
    vertical-align: middle;
  }

  .custom-th-class {
    text-align: end;
  }
}
</style>
<style src="@riophae/vue-treeselect/dist/vue-treeselect.min.css"></style>
